// Styles
// CoreUI Icons Set
// @import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';

// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';

@import '~react-jsonschema-form-validation/dist/react-jsonschema-form-validation.min.css';


// Import Main styles for this application
@import './assets/styles/style.scss';

.no-select {
	user-select: none;
}

.line-height-1 {
	line-height: 1;
}
